<template>
  <div class="professor">
    <Swiper></Swiper>
    <!-- <SubNav></SubNav> -->
    <!-- <div class="news">
     <Professor></Professor>
    </div> -->
    <!-- <div class="list">
    <p @click="goDetail(10086)">去詳情</p>
    </div> -->
    <!-- 师资培训介绍 -->
    <div class="introduce">
      <img
        class="introduceImg"
        :src="introduceImg"
        alt=""
        ondragstart="return false;"
        oncontextmenu="return false;"
      />
    </div>
    <!-- 课程体系 -->
    <div class="system">
      <img
        class="systemImg"
        :src="systemImg"
        alt=""
        ondragstart="return false;"
        oncontextmenu="return false;"
      />
    </div>
    <!-- 课程特色 -->
    <div class="feature">
      <img
        class="featureImg"
        :src="featureImg"
        alt=""
        ondragstart="return false;"
        oncontextmenu="return false;"
      />
    </div>
    <div class="sort">
      <img
        class="sortImg"
        v-for="(item, i) in sortImgList"
        :key="i"
        :src="item"
        alt=""
        ondragstart="return false;"
        oncontextmenu="return false;"
        @click="go(i)"
      />
    </div>
    <!-- 权威认证 -->
    <div class="authority">
      <img
        class="authorityImg"
        :src="authorityImg"
        alt=""
        ondragstart="return false;"
        oncontextmenu="return false;"
      />
    </div>
    <!-- 交付资料 -->
    <div class="data">
      <img
        class="dataImg"
        :src="dataImg"
        alt=""
        ondragstart="return false;"
        oncontextmenu="return false;"
      />
    </div>
    <!-- 精彩瞬间 -->

    <div class="wonderful">
      <img
        class="wonderfulImg"
        :src="wonderfulImg"
        alt=""
        ondragstart="return false;"
        oncontextmenu="return false;"
      />
    </div>
    <!-- 报名 -->
    <div class="signUp">
      <img
        class="signUpImg"
        :src="signUpImg"
        alt=""
        ondragstart="return false;"
        oncontextmenu="return false;"
      />
      <div class="top">
        <img
          class="back"
          @click="choice(1)"
          :src="state == 1 ? redBack : greenBack"
          alt=""
        />
        <img
          class="back"
          @click="choice(2)"
          :src="state == 2 ? redBack : greenBack"
          alt=""
        />
        <img
          class="back"
          @click="choice(3)"
          :src="state == 3 ? redBack : greenBack"
          alt=""
        />
      </div>
      <div class="top2">
        <div
          class="backZ"
          v-for="(item, i) in mess.data"
          :key="i"
          @click="choice(i + 1)"
        >
          <div class="content">{{ item.class_type }}</div>
          <div class="bottom" v-show="state == i+1">
            
            <p class="item" @click="goNew(item.enroll_link)" v-for="(item, i) in item.classinfo_set" :key="i">
              <img class="whitePoint" :src="whitePoint" alt="">
              <img :src="dateImg" alt="">
              <span class="inner">
               {{item.date}} <span style="color:red"> |</span> {{ item.title }} <span style="color:red"> |</span>  第{{item.number}}期 <span style="color:red"> |</span> {{item.plice}}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="message">
      <message></message>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive } from "vue";
import Swiper from "./modules/swiper.vue";
import SubNav from "./modules/subNav.vue";
import Professor from "./modules/professor.vue";
import Message from "@/components/message/Message.vue";
import axios from "@/utils/axios";

export default defineComponent({
  name: "home",
  components: {
    Swiper,
    SubNav,
    Message,
    Professor,
  },
  data() {
    return {
      introduceImg: require("@/assets/1new/师资培训/2.jpg"),
      systemImg: require("@/assets/1new/师资培训/3.jpg"),
      featureImg: require("@/assets/1new/师资培训/4.jpg"),
      sortImgList: [
        require("@/assets/1new/师资培训/5.jpg"),
        require("@/assets/1new/师资培训/6.jpg"),
        require("@/assets/1new/师资培训/7.jpg"),
      ],
      authorityImg: require("@/assets/1new/师资培训/8.jpg"),
      dataImg: require("@/assets/1new/师资培训/9.jpg"),
      wonderfulImg: require("@/assets/1new/师资培训/10.jpg"),
      signUpImg: require("@/assets/1new/师资培训/11.jpg"),
      redBack: require("@/assets/1new/师资培训/师资培训红色对钩.jpg"),
      greenBack: require("@/assets/1new/师资培训/师资培训绿色.jpg"),
      greenPoint: require("@/assets/1new/师资培训/师资培训绿点.jpg"),
      whitePoint: require("@/assets/1new/师资培训/师资培训白点.jpg"),
      dateImg: require("@/assets/1new/师资培训/图片1.png"),
      state: 1,
    };
  },
  methods: {
    goDetail(id: any) {
      console.log(id);
      this.$router.push({ name: "professorDetail", params: { id: id } });
    },
    goNew(a:any){
      window.open(a)
    },
    choice(i: any) {
      console.log(i);
      this.state = i;
    },
    go(a: any) {
      var aim;
      switch (a) {
        case 0:
          aim = "senior";
          break;
        case 1:
          aim = "middle";
          break;
        case 2:
          aim = "ability";
          break;
      }
      console.log(aim);

      this.$router.push("/" + aim);
    },
  },
  setup() {
    var mess = reactive({ data: "" });
    axios
      .get("https://www.xsy985.com/api/class/class/")
      .then(function (response) {
        console.log(response.data, "资讯");
        mess.data = response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
    return {
      mess,
    };
  },
});
</script>
<style lang="scss" scoped>
.professor {
  // 介绍
  .introduceImg {
    width: 1200px;
    height: 458px;
  }
  // 体系
  .systemImg {
    width: 1200px;
    height: 753px;
  }
  // 特色
  .featureImg {
    width: 1200px;
    height: 537px;
  }
  .sort {
    width: 1200px;
    padding-top: 40px;
    height: 380px;
    background-color: #fff;
    padding-left: 88px;
    box-sizing: border-box;
    .sortImg {
      width: 284px;
      height: 280px;
      margin-right: 70px;
      cursor: pointer;
    }
  }
  .authorityImg {
    width: 1200px;
    height: 394px;
  }
  .dataImg {
    width: 1200px;
    height: 568px;
  }
  .wonderfulImg {
    width: 1200px;
    height: 864px;
  }
  .signUp {
    background: #fff;
    padding-top: 50px;
    cursor: pointer;
    position: relative;
    .top {
      position: absolute;
      // z-index: 99;
      top: 190px;
      .back {
        width: 340px;
        height: 224px;
        margin-left: 35px;
      }
    }
    .top2 {
      position: absolute;
      top: 190px;
      .backZ {
        width: 340px;
        height: 224px;
        margin-left: 35px;
        display: inline-block;
        .content {
          margin-top: 100px;
          text-align: center;
          font-size: 24px;
        }
      }
    }
    .bottom {
      position: absolute;
      top: 300px;
      left: 0;
      width: 100%;
      // background-color:skyblue;
      .item{
        height: 66px;
        padding-left: 60px;
        border-bottom: 1px solid #000;
        // margin-bottom: 4px;
        width: 900px;
        margin-left: 145px;
        .whitePoint{
          position: absolute;
          left: 165px;
        margin-top:24px;
        }
        .inner{
          margin-left: 10px;
        font-size: 18px;
        display: inline-block;
        // background-color: red;
        height: 66px;
        line-height: 66px;
        position: absolute;
        }
      }
    }
  }
  .signUpImg {
    width: 1200px;
    height: 768px;
  }
  // 6
  .message {
    width: 1200px;
    // background-color: skyblue;
    display: block;
    height: 100%;
    margin-bottom: 20px;
    margin-top: 56px;
  }
}
</style>
