<template>
  <div class="index">
    <div class="box" v-for="(item, i) in list" :key="item" @click="goDetail(i)">
      <img class="img" :src="item.img" alt="" />
      <p class="name">{{ item.name }}</p>
      <p class="introduce">{{ item.introduce }}</p>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "professor",
  data() {
    return {
      list: [
        {
          img: require("@/assets/02.png"),
          name: "专家一",
          introduce:
            "专家简介简介专家简介简介专家简介简介sssssssss专家简介简介专家简介简介专家简介简介专家简介简介专家简介简介",
        },
        {
          img: require("@/assets/02.png"),
          name: "专家2",
          introduce:
            "专家简介简介专家简介简介专家简介简介专家简介简介专家简介简介专家简介简介专家简介简介专家简介简介",
        },
        {
          img: require("@/assets/02.png"),
          name: "专家一",
          introduce: "专家简介简介专家简家简介简介",
        },
        {
          img: require("@/assets/02.png"),
          name: "专家2",
          introduce:
            "专家简介简介专家简介简介专家简介简介专家ssss简介简介专家简介简介专家简介简介专家简介简介专家简介简介",
        },
        
      ],
    };
  },
  methods: {
    goDetail(id) {
      // console.log(id);
      this.$router.push({ name: "professorDetail", params: { id: id } });
    },
  },
});
</script>

<style lang="scss" scoped>
.index {
  width: 1200px;
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
  .box {
    float: left;
    width: 285px;
    height: 335px;
    background-color: #eeeeee;
    margin-left: 15px;
    margin-bottom: 25px;
    text-align: center;
    box-sizing: border-box;
    padding-left: 25px;
    padding-right: 25px;
    .img {
      margin-top: 25px;
      width: 120px;
      height: 120px;
      border-radius: 50%;
    }
    .name {
      margin-top: 15px;
      color: #338cca;
      font-size: 20px;
    }
    .introduce {
      margin-top: 15px;
      color: #338cca;
      font-size: 14px;
      height: 120px;
      line-height: 20px;
    }
  }
}
</style>